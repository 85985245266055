var todays_date = new Date();

 export class ReceivePaymentModel{
        customer : any;
        email: any;
        amount: number= 0;
        amount_received :number;
       	reference_no: any;
       	payment_method: number=1;
       	deposit_to: number=1;
       	payment_date:Date = todays_date;
       	invoice_data: any;
        vendor: any;
        bill_data: any;
        lend: any;
        actual_interest: any;
        actual_amount: any;
        payment_mode: any="Fully";
        tax_account: any;
        is_tax_deducted: boolean=false;
    }