import { UserOtherInfo } from './user_other_info';
import { Attachments, BillingAddress, ShippingAddress, CurrencyType} from './index';

export class User {
    email: string;
    password: string;
    first_name: string;
    last_name: string;
    confirm_password : string;
    user_other_info : UserOtherInfo;
    groups: any;
}
