 import { Attachments, BillingAddress, ShippingAddress, CurrencyType, OpeningBalance, Product} from './index';
 import { PlaceOfSupply } from './place_of_supply';
 import { GstTaxAmount } from './gst_tax_amount'; 
 var todays_date = new Date();

 export class InvoiceModel{
        customer : any ;
        customer_id:any;
        project: any;
        salesperson: any;
        email: string = "";
        invoice_type: number= 2;
        billing_address: string="";
       payment_term: any;
       term_id: string = "Net 15";
       invoice_date: Date = todays_date;
       invoiced_date:Date;
       invoiced_due_date :  Date = new Date(todays_date.getFullYear(),todays_date.getMonth(),todays_date.getDate()+15);
       // invoice_no: number = 0;
        invoice_no: any;
        invoice_no_type: string = "";
        ship_via: string = "";
        shipping_date: Date = new Date();
        tracking_no: number = 0;
        shipping_address: string = "";
        place_of_supply_id: number = 0;
        amounts_are: number = 2;
        message_displayed_on_invoice: string = "";
        statement_memo: string = "";
        balance_due: number = 0;
        round_off: number = 0;
        invoice_subtotal : number = 0;
        net_total: number = 0;
        deposit: number = 0;
        total: number = 0;
        discount : any = 0;
        discount_value : number = 0;
        discount_type : number = 1;
        place_of_supply : PlaceOfSupply;
        type_of_tax_include_on_amount : number = 0;
        shipping_amount : number =0;
        shipping_tax : number = 0;
        discount_percent_value : number = 0;
        products :any[] = [{
                        'id':1,
                        'service_date':'',
                        'product_id': '',
                        'product': "",
                        'product_service':'',
                        'sku':'',
                        'description':'',
                        'qty': 1,
                        'rate':0,
                        'amount':0,
                        'tax_amount':0,
                        'subtotal':0,
                        'tax':5,
                        'gst_tax_amount': []
        }];
        gst_tax_amount : GstTaxAmount[];
        notes: string = "";
        terms_and_condition: string = "";
        buttonV: string = "";
  }
