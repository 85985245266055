export class CurrencyType{
    id : number;//= models.AutoField(primary_key = True)
  	country : string;//= models.CharField(max_length = 100, blank = True)
  	country_short_name : string;//= models.CharField(max_length = 100, blank = True)
  	country_currency_name : string;//= models.CharField(max_length = 100, blank = True)
  	country_currency_symbol : string;//= models.CharField(max_length = 100, blank = True)
  	country_currency_iso_code : string;//= models.CharField(max_length = 100, blank = True)
  	country_currency_flag : string;//= models.CharField(max_length = 100, blank = True)


}
