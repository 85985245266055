export class Company{
	company_name : string = "";
	company_email : string = "";
	company_phone : string = "";
	company_logo : any ;
	pan_number : string  = "";
	cin_number : string  = "";
	default_selected_company : boolean  = false;
	mobile_no : string  = "";
	website : string  = "";
	fax_no : string  = "";
	information : string  = "";
	notes: string = "";
	terms_and_condition: string="";
	subscription_plan: number=1;
	company_location : any = {
		'id':1,
		'company_country':'',
		'company_street':'',
		'company_city':'',
		'company_state':'',
		'company_zip':''
	};

	gst_setting : any = {
		'id':1,
		'gstin':'',
		'is_gst_registerd':false,
		'is_composition_scheme_applicable': false,
		'composition_scheme_percentage': 10
	}

	accouting_method : any = {
		'id':3,
		'accounting_method_type': '',

	}

	fiscal_year : string= "AM";
	business_location: number= 47;
	currency_type: number=47;


}

