
export class ShippingAddress{
  id : number;//= models.AutoField(primary_key = True)
  street : string;//= models.CharField(max_length = 100, blank = True)
  city : string;//= models.CharField(max_length = 100, blank = True)
  state : string;//= models.CharField(max_length = 100, blank = True)
  postal_code: string; //= models.CharField(max_length = 10, blank= False)
  country : string;//= models.CharField(max_length =100)


}
