
var todays_date = new Date();

export class ProjectModel{
        name: string = "";
        description: string = "";
        customer : any ;
        billing_method: any;
        budget_type: any;
        add_budget: boolean = false;
        total_project_cost: any;
        rate_per_hour: any;
        total_budget_hour: any;
        total_budget_cost: any;
        users :any[] = [];
        tasks :any[] = [{
                        'id':1,
                        'task_name':'',
                        'description': '',
                        'rate_per_hour': 0,
                        'budget_hours':0,
                        'billable':false,
        }];
        
  }
