import { Attachments, BillingAddress, ShippingAddress, CurrencyType, OpeningBalance} from './index';


export class Customer{
    id : number ;
  	title : string;
  	first_name : string ;
  	middle_name : string ;
  	last_name : string ;
  	suffix : string ;
  	company : string;
  	display_name_as :string;
  	email : string ;
  	phone : number ;
  	mobile : number;
  	fax : string ;
  	other : string ;
  	website : string ;
  	is_subcustomer : boolean ;
  	parent_customer_id : number ;
  	billing_address : BillingAddress ;
  	shipping_address : ShippingAddress ;
  	is_same_as_billing : boolean ;
  	notes : string ;
  	opening_balance : OpeningBalance;
    attachments : Attachments;
  	make_inactive : boolean ;
  	max_attachments_size_allowed : number;
}
