import { User } from './user';

export class UserOtherInfo{

    gender : Gender; //models.ForeignKey(Gender, null = True, blank = True)
	mobile_no : number;// models.IntegerField(null = True, blank = True)
	phone_number : number; //models.IntegerField(null = True, blank = True)
	fax_number : number;//models.IntegerField(null = True, blank = True)
	website : string; //models.CharField(max_length = 100, null = True, blank = True)
	address : string; //models.CharField(max_length = 100, null = True, blank = True)
	city : string; //models.CharField(max_length = 100, null = True, blank = True)
	state : string; //models.CharField(max_length = 100, null = True, blank = True)
	zipcode : string; //models.CharField(max_length = 100, null = True, blank = True)
	country : string; //models.CharField(max_length = 100, null = True, blank = True)
	user : User;

}

export class Gender {
	gender_type : any = [{'id':1, 'gender_type':'Male'},
						{'id':2, 'gender_type':'Female'}];
	}