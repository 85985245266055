 import { Attachments, BillingAddress, ShippingAddress, CurrencyType, OpeningBalance, Product} from './index';
 import { PlaceOfSupply } from './place_of_supply';
 import { GstTaxAmount } from './gst_tax_amount'; 
 var todays_date = new Date();

export class SupplierBill{
 		vendor : any ;
        email: string = "";
        billing_address: string="";
		//payment_term: string = "Net 15";
       	bill_date: Date = todays_date;
       	bill_due_date :  Date = new Date(todays_date.getFullYear(),todays_date.getMonth(),todays_date.getDate()+15);
        bill_no: string = "";
        order_no : string ="";
        ship_via: string = "";
        shipping_date: Date = new Date();
        tracking_no: number = 0;
        shipping_address: string = "";  
        destination_of_supply_id: number = 0;
        source_of_supply_id: number = 0;
        amounts_are: number = 2;
        message_displayed_on_invoice: string = "";
        statement_memo: string = "";
        balance_due: number = 0;
        round_off: number = 0;
        bill_subtotal : number = 0;
        net_total: number = 0;
        deposit: number ;
        total: number = 0;
        discount : any = 0;
        discount_value : number = 0;
        discount_type : number = 1;
        destination_of_supply : string = "" ;
        payment_term: number = 1;
        source_of_supply : string = ""; 
        type_of_tax_include_on_amount : number = 0;
        shipping_amount : number = 0;
        shipping_tax : number ;

        discount_percent_value : number = 0;
        products :any[] = [{
                        'id':1,
                        'service_date':'',
                        'product_id': '',
                        'product': "",
                        'product_service':'',
                        'sku':'',
                        'description':'',
                        'expense_account':1,
                        'qty': 0,
                        'rate':0,
                        'amount':0,
                        'tax_amount':0,
                        'subtotal':0,
                        'tax':5,
                        'gst_tax_amount': []
        }];

        gst_tax_amount : GstTaxAmount[];
        terms_and_condition : string;
        notes : string;
        is_this_application_for_reverse_charge : boolean = false;
}